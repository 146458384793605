import { useStableProps } from '@/hooks/useStable';
import StandaloneVideoSlider from 'base/components/VideoSlider';

export const SvenskdamStandaloneVideoSlider: typeof StandaloneVideoSlider = (props) => {
  const stableProps = useStableProps(
    {
      options: { $swiper: { $arrow: { className: 'text-gray-300' } } },
    },
    props
  );

  return <StandaloneVideoSlider {...stableProps} />;
};

export default SvenskdamStandaloneVideoSlider;
